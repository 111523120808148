import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { TRACKING_PLAN_PATH } from '../constants';
import { Skeleton } from 'antd';
import { RedirectRoute } from '@components/routes/redirect';

const TrackingPlans = lazy(
  () =>
    import(
      /* webpackChunkName: "trackingPlans.trackingPlanList" */ '@components/trackingPlans/trackingPlanList'
    ),
);
const TrackingPlansDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "trackingPlans.trackingPlanView" */ '@components/trackingPlans/trackingPlanView'
    ),
);

export const TrackingPlansRoutes = () => (
  <Suspense fallback={<Skeleton active />}>
    <Switch>
      <Route exact path={TRACKING_PLAN_PATH.TRACKING_PLANS} component={TrackingPlans} />
      <Route
        path={TRACKING_PLAN_PATH.TRACKING_PLAN_DETAILS(':id')}
        component={TrackingPlansDetails}
      />
      <RedirectRoute />
    </Switch>
  </Suspense>
);
