import {
  ApiPolicy,
  ApiPermissionDefinition,
  PermissionActionInput,
  PolicyLevel,
} from '../api/types';
import { PermissionMap, ResourceCollection } from './types';

export const getCustomPermissionsCount = (policy?: ApiPolicy) => {
  if (!policy) {
    return 0;
  }

  return policy.permissions.reduce((acc, permission) => acc + permission.actions.length, 0);
};

const policyComparator = (a: ApiPolicy, b: ApiPolicy) => {
  if (a.level !== b.level) {
    return false;
  }

  if (a.level === PolicyLevel.USER) {
    return b.level === PolicyLevel.USER && a.userId === b.userId;
  }

  if (a.level === PolicyLevel.GROUP) {
    return b.level === PolicyLevel.GROUP && a.groupId === b.groupId;
  }

  return b.level === PolicyLevel.WORKSPACE && a.workspaceId === b.workspaceId;
};

export const findPolicy = (policies: ApiPolicy[], policy: ApiPolicy) => {
  const policyIndex = policies.findIndex((p) => policyComparator(p, policy));

  return {
    index: policyIndex,
    exists: policyIndex !== -1,
    policy: policyIndex !== -1 ? policies[policyIndex] : undefined,
  };
};

type PermissionsConfigMapConstructorParams = {
  definitions: ApiPermissionDefinition[];
  resourceCollection: ResourceCollection;
};

/**
 * Constructs a permission configuration map from permission definitions and resource collections.
 * This function takes permission definitions for different resource types and their associated actions,
 * and creates a structured map where:
 * - Each resource type has its own entry
 * - Each action under a resource type includes:
 *   - The action type and input type
 *   - A flag for inherited permissions
 *   - For LIST actions: associated resources and loading state
 *
 * @param definitions - Array of permission definitions for different resource types
 * @param resourceCollection - Collection of resources organized by resource type
 * @returns A structured permission map organizing permissions by resource and action types
 */
export const constructPermissionsConfig = ({
  definitions,
  resourceCollection,
}: PermissionsConfigMapConstructorParams): PermissionMap => {
  const map = {} as PermissionMap;

  definitions.forEach((resourceDef) => {
    const { name: resourceType, actions } = resourceDef;

    actions.forEach((actionDef) => {
      const { name: actionType, type: actionInput } = actionDef;

      map[resourceType] = {
        ...(map[resourceType] || {}),
        [actionType]:
          actionInput === PermissionActionInput.LIST
            ? {
                type: actionType,
                input: actionInput,
                hasInheritedPermissions: false,
                resources: resourceCollection[resourceType]?.resources || [],
                loading: resourceCollection[resourceType]?.loading,
              }
            : {
                type: actionType,
                input: actionInput,
                hasInheritedPermissions: false,
              },
      };
    });
  });

  return map;
};
