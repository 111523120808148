import { action, makeObservable, observable } from 'mobx';
import { IRootStore } from '@stores/index';
import { IGroupsStore } from './types';
import { ApiGroup } from '../api/types';

export class GroupsStore implements IGroupsStore {
  rootStore: IRootStore;

  @observable groups: ApiGroup[] = [];

  @observable groupsLoading = false;

  @observable groupsLoaded = false;

  constructor(root: IRootStore) {
    makeObservable(this);
    this.rootStore = root;
  }

  @action.bound
  setGroups(groups: ApiGroup[]) {
    this.groups = groups;
  }

  @action.bound
  setGroupsLoading(groupsLoading: boolean) {
    this.groupsLoading = groupsLoading;
  }

  @action.bound
  setGroupsLoaded(groupsLoaded: boolean) {
    this.groupsLoaded = groupsLoaded;
  }

  @action.bound
  getWorkspaceGroups(workspaceId: string) {
    return this.groups.filter((group) => group.workspaceIds.includes(workspaceId));
  }

  @action.bound
  getGroup(groupId: string) {
    return this.groups.find((group) => group.id === groupId);
  }
}
