export enum PermissionResourceType {
  DESTINATION = 'destination',
  EVENT_STREAM = 'event-stream',
  TABLE = 'table',
  AUDIENCE = 'audience',
  SQL_MODEL = 'sql-model',
  DATA_CATALOG = 'data-catalog',
  TRACKING_PLAN = 'tracking-plan',
  TRANSFORMATION = 'transformation',
  TRANSFORMATION_LIBRARY = 'transformation-library',
  PROFILES_PROJECT = 'profiles-project',
  RETRY_SYNC = 'retry-sync',
  ALERT_OVERRIDE = 'alert-override',
}

export enum PermissionActionType {
  EDIT = 'edit',
  CONNECT = 'connect',
  CREATE_DELETE = 'create-delete',
  PII_LIVE_EVENTS = 'pii-live-events',
  PII_FAILURE_SAMPLES = 'pii-failure-samples',
}

export enum PermissionActionInput {
  LIST = 'array',
  TOGGLE = 'boolean',
}

export type ApiPermissionAction = {
  name: PermissionActionType;
  resourceIds?: string[];
};

export type ApiPermission = {
  resourceType: PermissionResourceType;
  actions: ApiPermissionAction[];
};

export enum PolicyLevel {
  WORKSPACE = 'workspace',
  GROUP = 'group',
  USER = 'user',
}

export type DefaultWorkspacePolicy = {
  workspaceId: string;
  level: PolicyLevel.WORKSPACE;
  permissions: ApiPermission[];
};

export type GroupWorkspacePolicy = {
  workspaceId: string;
  groupId: string;
  level: PolicyLevel.GROUP;
  permissions: ApiPermission[];
};

type UserWorkspacePolicy = {
  workspaceId: string;
  userId: string;
  level: PolicyLevel.USER;
  permissions: ApiPermission[];
};

export type ApiPolicy = DefaultWorkspacePolicy | GroupWorkspacePolicy | UserWorkspacePolicy;

export type ApiGroup = {
  id: string;
  name: string;
  description: string;
  workspaceIds: string[];
};

export type ApiUser = {
  id: string;
  name: string;
  email: string;
  role: string;
  groupIds: string[];
  workspaceIds: string[];
};

export type ApiPermissionDefinition = {
  name: PermissionResourceType;
  actions: {
    name: PermissionActionType;
    type: PermissionActionInput;
  }[];
};

export type ApiResponse<T> = {
  data: T;
};
