import { action, observable, makeObservable, computed } from 'mobx';
import { TimeFilterValue } from '@utils/timeFilterUtils';
import { IRootStore } from '@stores/index';
import { EventStreamHealth, EventStreamHealthStore } from './eventStreamHealth';
import { RETLHealth, RETLHealthStore } from './rEtlHealth';
import { TrackingPlanHealthStore } from './trackingPlanHealth';
import { ActiveAlertsStore, IActiveAlertsStore } from './activeAlerts';
import { getTimeParams } from './utils';
import { Category, OverviewData, TableFilter } from './types';
import { allCategories } from './constants';

interface PeriodFilter {
  value: TimeFilterValue;
  currentPeriod: {
    start: string;
    end: string;
  };
  prevPeriod: {
    start: string;
    end: string;
  };
}

export interface IHealthDashboardStore {
  timeFilter: TimeFilterValue;
  periodFilter: PeriodFilter;
  tableFilter: TableFilter;
  loading: boolean;
  hasPiiPermission: boolean;
  overviewData: OverviewData[];
  eventStreamHealthStore: EventStreamHealth;
  retlHealthStore: RETLHealth;
  trackingPlanHealthStore: TrackingPlanHealthStore;
  activeAlertsStore: IActiveAlertsStore;
  showLatencyColumn: boolean;
  refresh(): void;
  setTimeFilter(time: string): void;
  setTableFilter(tableFilter: TableFilter): void;
  reset(): void;
  load(): void;
  loadPrevPeriodData(): void;
}

const DEFAULT_FILTERS = {
  time: TimeFilterValue.DAY,
  category: Category.EVENT_STREAM,
  subCategory: allCategories[Category.EVENT_STREAM].defaultSubFilter,
  status: allCategories[Category.EVENT_STREAM]?.statusFilters?.[0].value || 'all',
};

export class HealthDashboardStore implements IHealthDashboardStore {
  private rootStore: IRootStore;

  @observable public timeFilter: TimeFilterValue = DEFAULT_FILTERS.time;

  @observable public periodFilter: PeriodFilter = {
    value: DEFAULT_FILTERS.time,
    currentPeriod: getTimeParams(DEFAULT_FILTERS.time),
    prevPeriod: getTimeParams(DEFAULT_FILTERS.time, 'previous'),
  };

  @observable public tableFilter: TableFilter = {
    category: DEFAULT_FILTERS.category,
    subCategory: DEFAULT_FILTERS.subCategory,
    status: DEFAULT_FILTERS.status,
  };

  public eventStreamHealthStore: EventStreamHealth;

  public retlHealthStore: RETLHealth;

  public trackingPlanHealthStore: TrackingPlanHealthStore;

  public activeAlertsStore: IActiveAlertsStore;

  public showLatencyColumn = false;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.eventStreamHealthStore = new EventStreamHealthStore(this.rootStore);
    this.retlHealthStore = new RETLHealthStore(this.rootStore);
    this.trackingPlanHealthStore = new TrackingPlanHealthStore(this.rootStore);
    this.activeAlertsStore = new ActiveAlertsStore(this.rootStore);
  }

  @computed
  public get hasPiiPermission() {
    return this.rootStore.permissionsStore.hasPermission({
      resourceType: 'workspace',
      action: 'pii',
    });
  }

  @computed
  public get loading() {
    return (
      this.eventStreamHealthStore.loading ||
      this.trackingPlanHealthStore.loadingCurrentPeriod ||
      this.retlHealthStore.loading
    );
  }

  @action.bound
  public refresh(refreshAlerts = true) {
    this.updatePeriodFilter();
    this.eventStreamHealthStore.refresh();
    this.retlHealthStore.refresh();
    this.trackingPlanHealthStore.refresh();
    if (refreshAlerts) {
      this.activeAlertsStore.load();
    }
    this.loadPrevPeriodData();
  }

  private updatePeriodFilter() {
    this.periodFilter = {
      value: this.timeFilter,
      currentPeriod: getTimeParams(this.timeFilter),
      prevPeriod: getTimeParams(this.timeFilter, 'previous'),
    };
  }

  @action.bound
  public setTimeFilter(time: TimeFilterValue) {
    this.timeFilter = time;
    this.refresh(false);
  }

  @action.bound
  public setTableFilter(tableFilter: TableFilter) {
    this.tableFilter = tableFilter;
  }

  @computed
  public get overviewData() {
    const { eventStreamOverview, loading: eventStreamLoading } = this.eventStreamHealthStore;
    const { trackingPlanOverviewData, loadingCurrentPeriod: trackingPlanLoading } =
      this.trackingPlanHealthStore;
    const { retlSyncsCount, loading: retlHealthLoading } = this.retlHealthStore;
    const { activeAlertsOverview } = this.activeAlertsStore;
    return [
      {
        key: Category.EVENT_STREAM,
        title: 'Event Stream',
        description: 'Destinations with failures',
        total: eventStreamOverview.total,
        loading: eventStreamLoading,
        categories: [
          {
            label: 'cloud',
            value: eventStreamOverview.cloudCount,
          },
          {
            label: 'warehouse',
            value: eventStreamOverview.warehouseCount,
          },
        ],
        alerts: activeAlertsOverview[Category.EVENT_STREAM],
      },
      {
        key: Category.RETL,
        title: 'Reverse ETL',
        description: 'Latest runs with issues',
        total: retlSyncsCount.total,
        loading: retlHealthLoading,
        categories: [
          {
            label: 'failed',
            value: retlSyncsCount.failed,
          },
          {
            label: 'aborted',
            value: retlSyncsCount.aborted,
          },
        ],
        alerts: activeAlertsOverview[Category.RETL],
      },
      {
        key: Category.TRACKING_PLAN,
        title: 'Tracking Plans',
        description: 'Events with violations',
        total: trackingPlanOverviewData.total,
        loading: trackingPlanLoading,
        categories: [
          {
            label: trackingPlanOverviewData.sourcesCount === 1 ? 'source' : 'sources',
            value: trackingPlanOverviewData.sourcesCount,
          },
          {
            label: trackingPlanOverviewData.violationCount === 1 ? 'violation' : 'violations',
            value: trackingPlanOverviewData.violationCount,
          },
        ],
        alerts: activeAlertsOverview[Category.TRACKING_PLAN],
      },
    ];
  }

  @action.bound
  public async load() {
    this.eventStreamHealthStore.load('current');
    this.retlHealthStore.load();
    this.trackingPlanHealthStore.load();
    this.activeAlertsStore.load();
    this.loadPrevPeriodData();
  }

  @computed
  private get prevPeriodDataConfig() {
    return {
      eventStream: () => this.eventStreamHealthStore.load('previous'),
      trackingPlan: () => this.trackingPlanHealthStore.getPrevPeriodData(),
      retl: null,
    };
  }

  @action.bound
  public loadPrevPeriodData() {
    this.prevPeriodDataConfig[this.tableFilter.category]?.();
  }

  @action.bound
  public reset() {
    this.timeFilter = DEFAULT_FILTERS.time;
    this.tableFilter = {
      category: DEFAULT_FILTERS.category,
      subCategory: DEFAULT_FILTERS.subCategory,
      status: DEFAULT_FILTERS.status,
    };
    this.updatePeriodFilter();
    this.eventStreamHealthStore = new EventStreamHealthStore(this.rootStore);
    this.retlHealthStore = new RETLHealthStore(this.rootStore);
    this.trackingPlanHealthStore = new TrackingPlanHealthStore(this.rootStore);
    this.activeAlertsStore = new ActiveAlertsStore(this.rootStore);
  }
}
