import { action, computed, makeObservable, observable } from 'mobx';
import { IRootStore } from '@stores/index';
import { findPolicy, constructPermissionsConfig } from './utils';
import {
  IAccessManagementStore,
  IPoliciesStore,
  ResourceCollection,
  WorkspaceResourceCollection,
} from './types';
import {
  ApiPermissionDefinition,
  ApiPolicy,
  DefaultWorkspacePolicy,
  GroupWorkspacePolicy,
  PolicyLevel,
} from '../api/types';

export class PoliciesStore implements IPoliciesStore {
  rootStore: IRootStore;

  accessManagementStore: IAccessManagementStore;

  @observable policies: ApiPolicy[] = [];

  @observable policiesLoading = false;

  @observable private permissionDefinitions: ApiPermissionDefinition[] = [];

  @observable permissionDefinitionsLoaded = false;

  @observable private activePolicy: ApiPolicy | null = null;

  @observable modifyingPolicy = false;

  @observable workspaceResourceCollection: WorkspaceResourceCollection = {};

  constructor(root: IRootStore, accessManagementStore: IAccessManagementStore) {
    makeObservable(this);
    this.rootStore = root;
    this.accessManagementStore = accessManagementStore;
  }

  @action.bound
  setPolicies(policies: ApiPolicy[]) {
    this.policies = policies;
  }

  @action.bound
  setPoliciesLoading(policiesLoading: boolean) {
    this.policiesLoading = policiesLoading;
  }

  @action.bound
  updatePolicy(policy: ApiPolicy) {
    const { index, exists } = findPolicy(this.policies, policy);

    if (exists) {
      this.policies.splice(index, 1, policy);
    } else {
      this.policies.push(policy);
    }
  }

  @action.bound
  setActivePolicy(policy: ApiPolicy | null) {
    this.activePolicy = policy;
  }

  @action.bound
  setModifyingPolicy(modifyingPolicy: boolean) {
    this.modifyingPolicy = modifyingPolicy;
  }

  @action.bound
  setPermissionDefinitions(definitions: ApiPermissionDefinition[]) {
    this.permissionDefinitions = definitions;
  }

  @action.bound
  setPermissionDefinitionsLoaded(loaded: boolean) {
    this.permissionDefinitionsLoaded = loaded;
  }

  @action.bound
  updateWorkspaceResourceCollection(workspaceId: string, resourceCollection: ResourceCollection) {
    this.workspaceResourceCollection[workspaceId] = {
      ...this.workspaceResourceCollection[workspaceId],
      ...resourceCollection,
    };
  }

  @action.bound
  getDefaultWorkspacePolicy(workspaceId: string) {
    const existingPolicy = this.policies.find(
      (policy): policy is DefaultWorkspacePolicy =>
        policy.workspaceId === workspaceId && policy.level === PolicyLevel.WORKSPACE,
    );

    return (
      existingPolicy || {
        workspaceId,
        level: PolicyLevel.WORKSPACE,
        permissions: [],
      }
    );
  }

  @action.bound
  getGroupWorkspacePolicy(groupId: string, workspaceId: string) {
    const group = this.accessManagementStore.groupsStore.getGroup(groupId);
    if (!group) {
      return null;
    }

    const workspace = this.accessManagementStore.workspaces.find(
      (workspace) => workspace.id === workspaceId,
    );
    if (!workspace) {
      return null;
    }

    const existingPolicy = this.policies.find(
      (policy): policy is GroupWorkspacePolicy =>
        policy.level === PolicyLevel.GROUP &&
        policy.groupId === groupId &&
        policy.workspaceId === workspaceId,
    );

    return (
      existingPolicy || {
        workspaceId,
        groupId,
        level: PolicyLevel.GROUP,
        permissions: [],
      }
    );
  }

  @computed
  private get resourceCollection() {
    return this.activePolicy?.workspaceId
      ? this.workspaceResourceCollection[this.activePolicy.workspaceId] || {}
      : {};
  }

  @computed
  get permissionsConfig() {
    return constructPermissionsConfig({
      definitions: this.permissionDefinitions,
      resourceCollection: this.resourceCollection,
    });
  }
}
