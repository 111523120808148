import { IRootStore } from '@stores/index';
import { action, observable, makeObservable, computed } from 'mobx';
import moment from 'moment';
import { apiAuthCaller } from '@services/apiCaller';
import { ISourceStore } from '@stores/source';
import { getApiErrorMessage } from '@components/common/util/util';
import { DestinationDetails } from './eventStreamHealth';
import { Region } from '@components/common/constants';
import { Analytics } from '@lib/analytics';

export interface FilterOption {
  value: string | number | boolean;
  text: string;
}

interface ErrorData {
  lastSeen: number;
  eventName: string;
  eventType: string;
  count: number;
  sourceId: string;
  statusCode: number;
  reportedBy: string;
}

interface ErrorSampleData {
  sampleEvent: Record<string, unknown> | string;
  sampleResponse: string;
}

export interface ErrorListItem extends Omit<ErrorData, 'sourceId'> {
  id: number;
  source: ISourceStore;
  errorSampleLoading: boolean;
  sample?: ErrorSampleData;
}

export enum BATCH_STATUS {
  aborted = 'aborted',
  failed = 'failed',
  syncing = 'syncing',
}

interface FailedBatchError {
  error: string;
  errorCategory: string;
  sourceID: string;
  failedEventsCount: string;
  failedSyncsCount: string;
  firstHappened: string;
  lastHappened: string;
  status: BATCH_STATUS;
}

export enum RETRY_STATUS {
  idle = 'idle',
  loading = 'loading',
  success = 'success',
}

export interface WHErrorListItem {
  id: number;
  source: ISourceStore;
  failedEventsCount: number;
  failedSyncsCount: number;
  retryStatus: RETRY_STATUS;
  error: string;
  errorCategory: string;
  firstHappened: string;
  lastHappened: string;
  status: BATCH_STATUS;
}

export enum WH_ERROR_STAGES {
  syncs = 'syncs',
  preSync = 'preSync',
}

export interface DestinationHealth {
  destinationDetails: DestinationDetails;
  isWarehouseDest: boolean;
  errorsLoading: boolean;
  errorsList: ErrorListItem[];
  whErrorsList: WHErrorListItem[];
  whErrorStage: WH_ERROR_STAGES;
  retryStatus: RETRY_STATUS;
  setWhErrorStage(stage: WH_ERROR_STAGES): void;
  getErrorData(): void;
  getErrorSampleData(index: number): void;
  retryBatches(index?: number): void;
  reset(): void;
}

export class ESDestinationHealthStore implements DestinationHealth {
  private rootStore: IRootStore;

  @observable public destinationDetails: DestinationDetails;

  @observable public errorsLoading = false;

  @observable public errorsList: ErrorListItem[] = [];

  @observable public whErrorsList: WHErrorListItem[] = [];

  @observable public whErrorStage = WH_ERROR_STAGES.preSync;

  @observable public retryStatus: RETRY_STATUS = RETRY_STATUS.idle;

  constructor(destinationDetails: DestinationDetails, rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.destinationDetails = destinationDetails;
  }

  @action.bound
  public setErrorsLoading = (loading: boolean) => {
    this.errorsLoading = loading;
  };

  @action.bound
  public setWhErrorStage = (stage: WH_ERROR_STAGES) => {
    this.whErrorStage = stage;
  };

  @computed
  public get isWarehouseDest() {
    return this.destinationDetails.destination.destinationDefinition.category === 'warehouse';
  }

  public getErrorData = async () => {
    if (this.destinationDetails.abortSum === 0) {
      return;
    }
    this.errorsLoading = true;
    await Promise.all([
      this.getIngestionFailureData(),
      this.isWarehouseDest ? this.getSyncFailureData() : Promise.resolve(),
    ]);
    if (this.isWarehouseDest && this.errorsList.length === 0) {
      this.setWhErrorStage(WH_ERROR_STAGES.syncs);
    }
    this.errorsLoading = false;
  };

  @action.bound
  private getIngestionFailureData = async () => {
    const {
      messagesStore,
      sourcesListStore,
      healthDashboardStore,
      workspaceStore: { defaultRegion: region },
    } = this.rootStore;
    const { periodFilter } = healthDashboardStore;
    const { sourceById } = sourcesListStore;

    try {
      const { data: errorsData } = await apiAuthCaller().get<ErrorData[]>(
        `/destinations/${this.destinationDetails.destination.id}/eventStreamErrors`,
        { params: { start: periodFilter.currentPeriod.start, region } },
      );

      errorsData.sort((a, b) => b.lastSeen - a.lastSeen);
      const data = errorsData.map((x, i) => {
        const { sourceId, ...rest } = x;
        const source = sourceById(sourceId);
        if (!source) {
          return null;
        }
        return {
          ...rest,
          id: i,
          source,
          errorSampleLoading: false,
        };
      });

      this.errorsList = data.filter((x) => x !== null) as ErrorListItem[];
    } catch (error) {
      messagesStore.showErrorMessage(getApiErrorMessage(error, 'Failed to load errors data'));
    }
  };

  @action.bound
  public getErrorSampleData = async (index: number) => {
    const {
      messagesStore,
      workspaceStore: { defaultRegion: region },
    } = this.rootStore;
    const errorData = this.errorsList[index];
    errorData.errorSampleLoading = true;

    try {
      const res = await apiAuthCaller().get<ErrorSampleData>('/sampleEvent', {
        params: {
          sourceId: errorData.source.id,
          destinationId: this.destinationDetails.destination.id,
          eventName: errorData.eventName,
          eventType: errorData.eventType,
          reportedAt: moment.utc(errorData.lastSeen).toISOString(),
          statusCode: errorData.statusCode,
          reportedBy: errorData.reportedBy,
          region,
        },
      });

      const updatedErrorsList = this.errorsList.map((x, i) => {
        if (i === index) {
          return {
            ...x,
            errorSampleLoading: false,
            sample: res.data,
          };
        }
        return x;
      });
      this.errorsList = updatedErrorsList;
    } catch (error) {
      errorData.errorSampleLoading = false;
      messagesStore.showErrorMessage(getApiErrorMessage(error, 'Failed to load error sample data'));
    }
  };

  @action.bound
  private getSyncFailureData = async () => {
    const {
      messagesStore,
      sourcesListStore: { sourceById },
      healthDashboardStore: { periodFilter },
      workspaceStore: { defaultRegion: region },
    } = this.rootStore;
    const { id } = this.destinationDetails.destination;
    try {
      const {
        data: { failedBatches },
      } = await apiAuthCaller().get<{ failedBatches: FailedBatchError[] }>(
        `/warehouse/destinations/${id}/failed-batches`,
        {
          params: {
            ...periodFilter.currentPeriod,
            region,
          },
        },
      );
      failedBatches.sort((a, b) => moment(b.lastHappened).unix() - moment(a.lastHappened).unix());
      const mappedBatches = failedBatches.map((x, i) => {
        const { sourceID, ...rest } = x;
        const source = sourceById(sourceID);
        if (!source) {
          return null;
        }

        return {
          ...rest,
          errorCategory: x.errorCategory === 'default' ? '-' : x.errorCategory,
          failedEventsCount: Number(x.failedEventsCount),
          failedSyncsCount: Number(x.failedSyncsCount),
          id: i,
          source,
          retryStatus: RETRY_STATUS.idle,
        };
      });
      this.whErrorsList = mappedBatches.filter((x) => x !== null) as WHErrorListItem[];
      this.retryStatus = RETRY_STATUS.idle;
    } catch (error) {
      messagesStore.showErrorMessage(getApiErrorMessage(error, 'Failed to load syncs data'));
    }
  };

  public retryBatches = async (index?: number) => {
    const {
      messagesStore,
      healthDashboardStore: { periodFilter },
      workspaceStore: { defaultRegion: region },
    } = this.rootStore;
    const { id } = this.destinationDetails.destination;

    this.updateRetryStatus(RETRY_STATUS.loading, index);
    let payload: {
      start: string;
      end?: string;
      errorCategory?: string;
      sourceID?: string;
      status?: BATCH_STATUS;
      region: Region;
    } = {
      ...periodFilter.currentPeriod,
      region,
    };

    if (index !== undefined) {
      payload = {
        ...payload,
        errorCategory: this.whErrorsList[index].errorCategory,
        sourceID: this.whErrorsList[index].source.id,
        status: this.whErrorsList[index].status,
      };
    }

    try {
      const {
        data: { retriedSyncsCount },
      } = await apiAuthCaller().post<{ retriedSyncsCount: number }>(
        `/warehouse/destinations/${id}/failed-batches/retry`,
        payload,
      );
      this.updateRetryStatus(RETRY_STATUS.success, index);
      messagesStore.showInfoMessage(`Retries triggered for ${retriedSyncsCount} syncs`);
      Analytics.track({
        event: 'retryInitiated',
        properties: {
          destinationId: id,
          ...(index !== undefined && {
            errorCategory: payload.errorCategory,
            sourceId: payload.sourceID,
          }),
        },
      });
    } catch (error) {
      this.updateRetryStatus(RETRY_STATUS.idle, index);
      messagesStore.showErrorMessage(getApiErrorMessage(error, 'Failed to trigger retry'));
    }
  };

  private updateRetryStatus = (status: RETRY_STATUS, index?: number) => {
    if (index !== undefined) {
      this.whErrorsList = this.whErrorsList.map((x, i) => {
        if (i === index) {
          return {
            ...x,
            retryStatus: status,
          };
        }
        return x;
      });
    } else {
      this.retryStatus = status;
    }
  };

  @action.bound
  public reset = () => {
    this.whErrorStage = WH_ERROR_STAGES.preSync;
    this.retryStatus = RETRY_STATUS.idle;
    this.whErrorsList = this.whErrorsList.map((x) => ({
      ...x,
      retryStatus: RETRY_STATUS.idle,
    }));
  };
}
