import React from 'react';
import theme from '@css/theme';
import { Button, ButtonProps as AntButtonProps, TooltipProps } from 'antd';
import styled, { css } from 'styled-components';

type ExtendedSizeType = AntButtonProps['size'] | 'xlarge';
type ExtendedType = AntButtonProps['type'] | 'secondary';

export interface ButtonProps extends Omit<AntButtonProps, 'size' | 'type'> {
  type?: ExtendedType;
  size?: ExtendedSizeType;
  variant?: Variant;
  tooltip?: TooltipProps & { show?: boolean };
  noPadding?: boolean;
}

const antSizeToTokenSize = {
  large: 'fontSize',
  middle: 'fontSize',
  small: 'fontSizeSM',
  xlarge: 'fontSizeLG',
} satisfies Record<Exclude<ExtendedSizeType, undefined>, keyof typeof theme.token>;

const defaultTypeColors = {
  default: {
    border: theme.token.colorBorder,
    color: theme.token.colorText,
    hoverBackground: theme.token.colorBgTextHover,
    activeBackground: theme.token.colorBgTextActive,
    outlineColor: theme.token.colorBorder,
  },
  danger: {
    border: theme.token.colorErrorBorder,
    color: theme.token.colorError,
    hoverBackground: theme.token.colorErrorBg,
    activeBackground: theme.token.colorErrorBgHover,
    outlineColor: theme.token.colorErrorBorder,
  },
  confirm: {
    border: theme.token.colorSuccessBorder,
    color: theme.token.colorSuccess,
    hoverBackground: theme.token.colorSuccessBg,
    activeBackground: theme.token.colorSuccessBgHover,
    outlineColor: theme.token.colorSuccessBorder,
  },
};

export type Variant = keyof typeof defaultTypeColors;
export type CustomSize = keyof typeof antSizeToTokenSize;

const mapSizeToWeight = {
  large: 600,
  middle: 500,
  small: 400,
  xlarge: 600,
} as const;

const commonButtonStyles = css<ButtonProps>`
  font-weight: ${({ size = 'middle' }) => mapSizeToWeight[size]};

  &:disabled {
    /* prevent layout shift with an invisible border */
    border-color: rgba(0, 0, 0, 0);
  }

  &:not(:disabled):focus {
    outline-offset: 2px !important;
    outline-width: 2px !important;
  }
`;

export const DefaultButton = styled((props: ButtonProps) => {
  const { variant, size, type, ...rest } = props;
  return <Button type="default" size={size === 'xlarge' ? 'large' : size} {...rest} />;
})<ButtonProps>`
  ${commonButtonStyles}

  border-color: ${({ variant = 'default' }) => defaultTypeColors[variant].border};
  color: ${({ variant = 'default' }) => defaultTypeColors[variant].color};

  &:hover:enabled {
    border-color: ${({ variant = 'default' }) => defaultTypeColors[variant].border} !important;
    color: ${({ variant = 'default' }) => defaultTypeColors[variant].color} !important;
    background: ${({ variant = 'default' }) =>
      defaultTypeColors[variant].hoverBackground} !important;
  }

  &:active:enabled {
    border-color: ${({ variant = 'default' }) => defaultTypeColors[variant].border} !important;
    color: ${({ variant = 'default' }) => defaultTypeColors[variant].color} !important;
    background: ${({ variant = 'default' }) =>
      defaultTypeColors[variant].activeBackground} !important;
  }

  &focus:enabled {
    outline-color: ${({ variant = 'default' }) =>
      defaultTypeColors[variant].outlineColor} !important;
  }
`;

const primaryButtonColors = {
  default: {
    background: theme.token.colorPrimaryBase,
    hoverBackground: theme.token.colorPrimaryHover,
    activeBackground: theme.token.colorPrimaryActive,
    outlineColor: theme.token.colorPrimaryBorder,
  },
  danger: {
    background: theme.token.colorErrorBase,
    hoverBackground: theme.token.colorErrorHover,
    activeBackground: theme.token.colorErrorActive,
    outlineColor: theme.token.colorErrorBorder,
  },
  confirm: {
    background: theme.token.colorSuccessVariant,
    hoverBackground: theme.token.colorSuccessHover,
    activeBackground: theme.token.colorSuccessActive,
    outlineColor: theme.token.colorSuccessBorder,
  },
};

export const PrimaryButton = styled((props: ButtonProps) => {
  const { variant, size, type, ...rest } = props;
  return <Button type="primary" size={size === 'xlarge' ? 'large' : size} {...rest} />;
})<ButtonProps>`
  ${commonButtonStyles}

  background: ${({ variant = 'default' }) => primaryButtonColors[variant].background};

  &:hover:enabled {
    background: ${({ variant = 'default' }) =>
      primaryButtonColors[variant].hoverBackground} !important;
  }

  &:active:enabled {
    background: ${({ variant = 'default' }) =>
      primaryButtonColors[variant].activeBackground} !important;
  }

  &:focus:enabled {
    outline-color: ${({ variant = 'default' }) =>
      primaryButtonColors[variant].outlineColor} !important;
  }
`;

const secondaryTypeColors = {
  default: {
    color: theme.token.colorPrimaryText,
    background: theme.token.colorPrimarySubdued,
    hoverBackground: theme.token.colorPrimarySubduedHover,
    activeBackground: theme.token.colorPrimarySubduedActive,
    outlineColor: theme.token.colorPrimaryBorder,
  },
  danger: {
    color: theme.token.colorErrorText,
    background: theme.token.colorErrorSubdued,
    hoverBackground: theme.token.colorErrorSubduedHover,
    activeBackground: theme.token.colorErrorSubduedActive,
    outlineColor: theme.token.colorErrorBorder,
  },
  confirm: {
    color: theme.token.colorSuccessText,
    background: theme.token.colorSuccessSubdued,
    hoverBackground: theme.token.colorSuccessSubduedHover,
    activeBackground: theme.token.colorSuccessSubduedActive,
    outlineColor: theme.token.colorSuccessBorder,
  },
};

export const SecondaryButton = styled((props: ButtonProps) => {
  const { variant, size, type, ...rest } = props;
  return <Button size={size === 'xlarge' ? 'large' : size} {...rest} />;
})<ButtonProps>`
  ${commonButtonStyles}

  color: ${({ variant = 'default' }) => secondaryTypeColors[variant].color};

  /* prevent layout shift with an invisible border */
  border-color: rgba(0, 0, 0, 0) !important;

  background: ${({ variant = 'default' }) => secondaryTypeColors[variant].background};

  &:hover:enabled {
    background: ${({ variant = 'default' }) =>
      secondaryTypeColors[variant].hoverBackground} !important;
    color: ${({ variant = 'default' }) => secondaryTypeColors[variant].color} !important;
  }

  &:active:enabled {
    color: ${({ variant = 'default' }) => secondaryTypeColors[variant].color} !important;
    background: ${({ variant = 'default' }) =>
      secondaryTypeColors[variant].activeBackground} !important;
  }

  &:focus:enabled {
    outline-color: ${({ variant = 'default' }) =>
      secondaryTypeColors[variant].outlineColor} !important;
  }
`;

export const LinkButton = styled((props: ButtonProps) => {
  const { variant, size, type, ...rest } = props;
  return <Button type={type !== 'link' && type !== 'text' ? 'link' : type} {...rest} />;
})<ButtonProps>`
  ${({ noPadding }) => noPadding && 'padding-inline: 0 !important;'}

  font-weight: ${({ size = 'middle' }) => mapSizeToWeight[size]};

  font-size: ${({ size = 'middle' }) => `${theme.token[antSizeToTokenSize[size]]}px !important;`}

  line-height: ${({ size = 'middle' }) => `${theme.token[antSizeToTokenSize[size]]}px;`}

  > *:hover:enabled,
  > *:focus:enabled {
    cursor: pointer;
    text-decoration: underline;
    outline: none;
  }

  &focus-visible:enabled {
    outline: 0;
    outline-offset: 0;
  }
`;

export const IconFix = styled.span`
  :not(:last-child) {
    margin-inline-end: ${theme.token.marginXS}px;
  }
`;
