import { Region } from '@components/common/constants';
import { getApiErrorMessage } from '@components/common/util/util';
import { apiAuthCaller } from '@services/apiCaller';
import { IFeaturesStore } from '@stores/features';
import { IWorkspaceStore, WorkspaceEnvironment } from '@stores/workspace';
import { WorkspaceStatus } from '@stores/workspace/constants';
import uniq from 'lodash/uniq';
import { action, computed, makeObservable, observable } from 'mobx';
import { PlanNameType } from './billing';
import {
  LegacyInviteRole,
  Member,
  MemberStore,
  OrganizationRole,
  ServerStatus,
  UserRoles,
} from './member';
import { IMessageStore } from './messages';
import { IPermissions } from './permissions';
import { IUserStore } from './user';

type Seed = {
  id: string;
  name: string;
  planName: PlanNameType;
  grafanaURL?: string;
  ownerId: string;
  enforcedMfa?: boolean;
  userRoles: {
    user: { id: string; email: string; name: string };
    roles: UserRoles;
  }[];
  invitedUsers: {
    id: string;
    name: string;
    email: string;
    status: ServerStatus;
    role: LegacyInviteRole;
    userRoles: UserRoles;
    createdAt: string;
  }[];
  workspaceId: string;
};

export enum SOURCE_DEFINITION_CATEGORY {
  ALL = 'All',
  EVENT_STREAM = 'EventStream',
  WAREHOUSE_ACTION = 'WarehouseAction',
  CLOUD_EXTRACT = 'CloudExtract',
}

export type SourceDefinitionCategory =
  | SOURCE_DEFINITION_CATEGORY.EVENT_STREAM
  | SOURCE_DEFINITION_CATEGORY.WAREHOUSE_ACTION
  | SOURCE_DEFINITION_CATEGORY.CLOUD_EXTRACT;

interface EventBySourceDefinitionCategory {
  workspaceId: string;
  sourceCategory: SourceDefinitionCategory;
  count: number;
}

export interface EventBySourceAndTimeFrame {
  workspaceId: string;
  sourceId: string;
  sourceName: string;
  sourceDefinitionId: string;
  deleted: boolean;
  sourceCategory?: SourceDefinitionCategory;
  bucket: number;
  numOfSources: number;
  count: number;
}

interface Workspace {
  id: string;
  name: string;
  environment: WorkspaceEnvironment;
  status: WorkspaceStatus;
  defaultRegion: Region;
}

export interface IOrganization {
  id: string;
  name: string;
  isLoading: boolean;
  eventsLoaded: boolean;
  planName?: PlanNameType;
  eventsCountsBySourceDefinitionCategory: EventBySourceDefinitionCategory[];
  eventsCountsBySourceAndTimeBucket: EventBySourceAndTimeFrame[];
  grafanaURL?: string;
  ownerId: string;
  members: Member[];
  updateEnforcedMfa: (val: boolean) => void;
  updateName: (val: string) => Promise<boolean>;
  seed: (obj: Seed) => void;
  reset: () => void;
  setMemberStatus: (email: string) => void;
  getEventsCountsBySourceDefinitionCategory: (
    startTime?: number,
    workspaceId?: string,
  ) => Promise<void>;
  getEventsCountsBySourceAndTimeBucket: (
    granularity: string,
    start: number,
    workspaceId?: string,
  ) => void;
  workspaces: Workspace[];
  accessibleWorkspaces: Workspace[];
  workspaceRegions: Region[];
  disabledWorkspaces: Workspace[];
  activeWorkspaces: Workspace[];
  enforcedMfa?: boolean;
}

type RootStore = {
  userStore: IUserStore;
  messagesStore: IMessageStore;
  permissionsStore: IPermissions;
  featuresStore: IFeaturesStore;
  workspaceStore: IWorkspaceStore;
  organizationStore: IOrganization;
};

export class OrganizationStore implements IOrganization {
  @observable public id = '';

  @observable public name = '';

  @observable public planName?: PlanNameType = undefined;

  @observable public ownerId = '';

  @observable public isLoading = true;

  @observable public eventsLoaded = false;

  @observable public grafanaURL: string | undefined = '';

  @observable public members: MemberStore[] = [];

  @observable private rootStore: RootStore;

  @observable public enforcedMfa?: boolean = undefined;

  @observable public eventsCountsBySourceDefinitionCategory: EventBySourceDefinitionCategory[] = [];

  public eventsCountsBySourceAndTimeBucket: EventBySourceAndTimeFrame[] = [];

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action.bound
  seed(data: Seed) {
    this.id = data.id;
    this.name = data.name;
    this.planName = data.planName;
    this.grafanaURL = data.grafanaURL;
    this.ownerId = data.ownerId;
    this.enforcedMfa = data.enforcedMfa;
    this.populateMembers(data);
    this.isLoading = false;
  }

  private populateMembers({ userRoles, invitedUsers, workspaceId }: Seed) {
    this.members = userRoles.map(
      (userRole) =>
        new MemberStore(
          {
            id: userRole.user.id,
            name: userRole.user.name,
            email: userRole.user.email,
            roles: userRole.roles,
            status: undefined,
            invitedAt: '',
            workspaceId,
          },
          this.rootStore,
        ),
    );

    this.members = this.members.concat(
      invitedUsers.map(
        (user) =>
          new MemberStore(
            {
              id: user.id,
              name: user.name,
              email: user.email,
              status: user.status,
              roles: user.userRoles,
              invitedAt: user.createdAt,
              workspaceId,
            },
            this.rootStore,
          ),
      ),
    );
  }

  @action.bound
  async updateName(name: string) {
    const {
      messagesStore: { showErrorMessage },
    } = this.rootStore;
    try {
      await apiAuthCaller().post(`/updateOrgName`, { name });
      this.name = name;
      return true;
    } catch (error) {
      showErrorMessage(getApiErrorMessage(error, 'Failed to update organization name'));
    }
    return false;
  }

  @action.bound
  setMemberStatus(email: string) {
    const member = this.members.find((user) => user.email === email);
    member!.status = 'pending';
  }

  @action.bound
  async updateEnforcedMfa(enforcedMfa: boolean) {
    const {
      userStore: { updateEnforcedMfa },
      messagesStore,
    } = this.rootStore;
    try {
      await apiAuthCaller().post(`/enforceMfa`, { enforcedMfa });
      this.enforcedMfa = enforcedMfa;
      await updateEnforcedMfa();
      messagesStore.showSuccessMessage('Successfully updated enforced MFA option');
    } catch (error) {
      messagesStore.showErrorMessage('Failed to update enforced MFA option');
    }
  }

  reset() {
    this.id = '';
    this.name = '';
    this.grafanaURL = '';
    this.ownerId = '';
    this.members = [];
    this.isLoading = true;
  }

  private getWorkspaceIdQueryParam(workspaceId?: string) {
    let workspaceIds: string[] = [];
    if (workspaceId && workspaceId !== 'allWorkspaces') {
      workspaceIds = [workspaceId];
    } else {
      workspaceIds = this.accessibleWorkspaces.map((w) => w.id);
    }
    return `workspaceIds=${workspaceIds.join(',')}`;
  }

  @action.bound
  async getEventsCountsBySourceDefinitionCategory(startTime?: number, workspaceId?: string) {
    const { messagesStore } = this.rootStore;
    if (!startTime) {
      return;
    }
    try {
      const workspaceQueryParam = this.getWorkspaceIdQueryParam(workspaceId);
      const responses = await Promise.all(
        this.workspaceRegions.map(async (region) =>
          apiAuthCaller({ nonWorkspaceRoute: true, region }).get(
            `organizations/${this.id}/eventsCountBySourceCategory?${workspaceQueryParam}`,
            {
              params: {
                region,
                start: startTime,
              },
            },
          ),
        ),
      );
      this.eventsLoaded = true;
      this.eventsCountsBySourceDefinitionCategory = responses
        .map((response) => response.data)
        .reduce((acc, data) => [...acc, ...data], []);
    } catch (e) {
      messagesStore.showErrorMessage('Unable get events counts');
    }
  }

  @action.bound
  async getEventsCountsBySourceAndTimeBucket(
    granularity: string,
    start: number,
    workspaceId?: string,
  ) {
    const { messagesStore } = this.rootStore;
    try {
      this.eventsCountsBySourceAndTimeBucket = [];
      const workspaceQueryParam = this.getWorkspaceIdQueryParam(workspaceId);
      const responses = await Promise.all(
        this.workspaceRegions.map(async (region) =>
          apiAuthCaller({ nonWorkspaceRoute: true, region }).get(
            `organizations/${this.id}/eventsCountBySourceAndTimeBucket?${workspaceQueryParam}`,
            {
              params: {
                granularity,
                start,
                region,
              },
            },
          ),
        ),
      );
      this.eventsCountsBySourceAndTimeBucket = responses
        .map((response) => response.data)
        .reduce((acc, data) => [...acc, ...data], []);
    } catch (e) {
      messagesStore.showErrorMessage('Unable get events counts');
    }
  }

  @computed get workspaces() {
    const {
      userStore: { workspaces, selectedWorkspaceId },
    } = this.rootStore;
    return workspaces
      .filter((ws) => ws.organizationId === this.id)
      .sort((a, b) => Number(b.id === selectedWorkspaceId) - Number(a.id === selectedWorkspaceId));
  }

  @computed get accessibleWorkspaces() {
    const {
      userStore: { workspaces, selectedWorkspaceId, organizations },
      featuresStore,
    } = this.rootStore;
    if (!featuresStore.has('NEW_ROLES_FLOW')) {
      return this.workspaces;
    }
    const organization = organizations.find((org) => org.id === this.id)!;
    return workspaces
      .filter(
        (ws) =>
          ws.organizationId === this.id &&
          ws.status === WorkspaceStatus.ACTIVE &&
          (organization.role === OrganizationRole.admin || ws.roles.length > 0),
      )
      .sort((a, b) => Number(b.id === selectedWorkspaceId) - Number(a.id === selectedWorkspaceId));
  }

  @computed get workspaceRegions() {
    return uniq(this.workspaces.map((w) => w.defaultRegion));
  }

  @computed get disabledWorkspaces() {
    return this.workspaces.filter((ws) => ws.status === WorkspaceStatus.DISABLED);
  }

  @computed get activeWorkspaces() {
    return this.workspaces.filter((ws) => ws.status === WorkspaceStatus.ACTIVE);
  }
}
