import React from 'react';
import { ButtonProps } from '@ui-library/button';
import { H4 } from '@ui-library/typography';
import { Flex } from '../flex';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import classes from './custom.module.scss';
import { CrossIcon, CustomButton, CustomContent, CustomModalContainer } from './style';

export interface ModalProps {
  title?: string | JSX.Element;
  onClose: () => void;
  onCross?: () => void;
  content: JSX.Element | string;
  onSave?: () => void;
  isSaveDisabled?: boolean;
  loading?: boolean;
  saveLabel?: string | JSX.Element;
  saveLabelBtnType?: { type: ButtonProps['type']; variant?: ButtonProps['variant'] };
  cancelLabelBtnType?: { type: ButtonProps['type']; variant?: ButtonProps['variant'] };
  showCross?: boolean;
  showClose?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  modalClass?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
  cancelLabel?: string | JSX.Element;
  isCloseButtonLoading?: boolean;
  hideMargin?: boolean;
}

/* eslint-disable import/no-default-export */
export default ({
  title,
  onClose,
  onCross = onClose,
  content,
  onSave = () => null,
  isSaveDisabled = false,
  loading = false,
  saveLabel = '',
  showCross = true,
  showClose = true,
  size = 'sm',
  modalClass = '',
  onKeyDown = () => null,
  saveLabelBtnType = { type: 'primary' },
  cancelLabelBtnType = { type: 'default' },
  cancelLabel = 'Cancel',
  isCloseButtonLoading = false,
  hideMargin,
}: ModalProps) => {
  const sizeClass = `${size}_modal`;
  return (
    <CustomModalContainer
      className={`${sizeClass} ${modalClass}`}
      onKeyDown={onKeyDown}
      tabIndex={-1}
      role="button"
      data-testid="customModal"
    >
      <Flex alignItems="center">
        {title && <H4 className={classes.header}>{title}</H4>}
        {showCross && (
          <CrossIcon onClick={onCross} data-testid="btnCloseIcon" icon={regular('xmark')} />
        )}
      </Flex>
      <CustomContent hideMargin={hideMargin}>{content}</CustomContent>
      <Flex justifyContent="flex-end">
        {showClose && (
          <CustomButton
            {...cancelLabelBtnType}
            onClick={onClose}
            data-testid="btnClose"
            loading={isCloseButtonLoading}
          >
            {cancelLabel}
          </CustomButton>
        )}
        {!!saveLabel && (
          <CustomButton
            {...saveLabelBtnType}
            size="large"
            className="m-l-sm"
            disabled={isSaveDisabled}
            onClick={onSave}
            loading={loading}
            data-testid="btnSave"
          >
            {saveLabel}
          </CustomButton>
        )}
      </Flex>
    </CustomModalContainer>
  );
};
