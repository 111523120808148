import React, { ReactNode } from 'react';
import { Radio as AntRadio, RadioGroupProps } from 'antd';
import { StyledRadioButtonGroup } from './styles';
import { Flex } from '@ui-library/flex';
import styled from 'styled-components';
import theme from '@css/theme';
import { Tooltip } from '@ui-library/tooltip';

export { StyledRadio as Radio } from './styles';

export type { RadioGroupProps } from 'antd';

interface CustomRadioGroupProps {
  items: { id: string; element: ReactNode; className?: string }[];
  orientation?: 'horizontal' | 'vertical';
  gap?: string;
}

const orientationMap = {
  horizontal: 'row',
  vertical: 'column',
};

export const RadioGroup = ({
  onChange,
  value,
  items,
  className,
  disabled = false,
  orientation = 'vertical',
  gap = '0.25rem',
}: RadioGroupProps & CustomRadioGroupProps) => (
  <StyledRadioButtonGroup onChange={onChange} value={value} className={className}>
    <Flex gap={gap} flexDirection={orientationMap[orientation]}>
      {items.map((i) => (
        <AntRadio className={i.className} value={i.id} key={i.id} disabled={disabled}>
          {i.element}
        </AntRadio>
      ))}
    </Flex>
  </StyledRadioButtonGroup>
);

export const RadioButtonGroup: React.FC<
  RadioGroupProps & {
    items: { label: React.ReactNode; value: string; tooltip?: string; disabled?: boolean }[];
  }
> = ({ items, ...props }) => (
  <StyledRadioButtonGroup {...props} optionType="button" buttonStyle="solid">
    {items.map(({ label, value, tooltip, disabled }) => (
      <Tooltip title={tooltip} key={value}>
        <AntRadio.Button value={value} key={value} disabled={disabled}>
          {label}
        </AntRadio.Button>
      </Tooltip>
    ))}
  </StyledRadioButtonGroup>
);

export const OptionCard = styled(Flex)<{
  isSelected: boolean;
  disabled?: boolean;
  defaultColor?: boolean;
  isFullWidth?: boolean;
}>`
  ${({ disabled, isSelected }) =>
    disabled
      ? `
    pointer-events: none;
    background: ${theme.token.colorBgContainerDisabled};
    border: 2px solid ${theme.token.colorBorderTertiary};
    `
      : `
    background: ${isSelected ? theme.token.colorPrimaryBg : 'white'};
    border:  ${
      isSelected
        ? `2px solid ${theme.token.colorPrimaryBorder}`
        : `1px solid ${theme.token.colorBorder}`
    };
    `}
  padding: ${theme.token.padding}px;
  border-radius: ${theme.token.borderRadiusSM}px;
  cursor: pointer;
  ${({ isFullWidth }) => (isFullWidth ? 'width: 100%;' : '')}
  & svg {
    color: ${({ isSelected, defaultColor }) =>
      isSelected && !defaultColor ? theme.token.colorPrimaryBorder : undefined};
  }
`;
