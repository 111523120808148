import type { IFlagsmith } from 'flagsmith';
import { LOCAL_FEATURE_FLAGS, flagsmithConfig } from '@config';

type User = {
  id: string;
  workspaceId: string;
  organizationId: string;
  isNewUser?: boolean;
  planType?: string;
  environment?: string;
};

export enum FeatureFlagKeys {
  'deviceModeTransformations' = 'device-mode-transformations-web',
  'transformerSecrets' = 'transformer-secrets',
  'rs360EnableWht' = 'rs360-enable-wht',
  'latencyOnDestinationEventsTab' = 'latency-on-destination-events-tab',
  'delaysAlert' = 'delays-alert',
  'text2sql' = 'text-2-sql',
  'mtuUsage' = 'mtu-usage',
  'showWarehouseLatency' = 'show-warehouse-latency',
  'enableCohortAudience' = 'enable-cohort-audience',
  'enableDataCatalogCustomTypes' = 'enable-data-catalog-custom-types',
  'enabledDataCatalogTPWorkspaceCopy' = 'enable-data-catalog-tp-workspace-copy',
  'codegenUI' = 'codegen-ui',
  'reportingDelay' = 'reporting-delay',
  'barGraphForWarehouseDest' = 'bar-graph-for-warehouse-dest',
  'enableDataCatalogComplexCustomTypes' = 'enable-data-catalog-complex-custom-types',
  'enableDataCatalogCustomTypesArrayItems' = 'enable-data-catalog-custom-types-array-items',
  'enableRetlCursorColumn' = 'enable-retl-cursor-column',
  'enableDestinationEventDetails' = 'obs-enable-destination-event-details',
  'restrictLiveEvents' = 'restrict-live-events',
  'enableTPNewOverview' = 'enable-tracking-plan-new-overview',
  'enableTrackingPlansEventNewAPIResponse' = 'enable-tracking-plans-event-new-api-response',
  'trackingPlanValidateStageInUTLiveEvents' = 'tracking-plan-validate-stage-ut-live-event',
  'autosaveTransformationsTestEvents' = 'autosave_testevents',
  'enableNewPermissionsModel' = 'enable-new-permissions-model',
}

type FeatureFlagsWithPayload = {
  [K in keyof typeof featureFlagsDefaults]: 'payload' extends keyof (typeof featureFlagsDefaults)[K]
    ? K
    : never;
}[keyof typeof featureFlagsDefaults];

type FeatureFlagPayloads = {
  [K in FeatureFlagsWithPayload]: (typeof featureFlagsDefaults)[K]['payload'];
};

// List of destination feature flags
export enum DestinationFeatureFlags {
  'vdmNext' = 'vdm-next',
}

// ts-prune-ignore-next
export const destinationFeatureFlagKeys = Object.values(DestinationFeatureFlags);
// ts-prune-ignore-next
export const featureFlagKeys = Object.values(FeatureFlagKeys);

const destinationFeatureFlagsDefaults = {
  [DestinationFeatureFlags.vdmNext]: {
    payload: {},
    default: false,
  },
} as const;

// ts-prune-ignore-next
export const featureFlagsDefaults = {
  [FeatureFlagKeys.deviceModeTransformations]: {
    default: false,
  },
  [FeatureFlagKeys.transformerSecrets]: {
    default: false,
  },
  [FeatureFlagKeys.rs360EnableWht]: {
    default: LOCAL_FEATURE_FLAGS.RS360_ENABLE_WHT === 'true',
  },
  [FeatureFlagKeys.latencyOnDestinationEventsTab]: {
    default: false,
  },
  [FeatureFlagKeys.delaysAlert]: {
    default: false,
  },
  [FeatureFlagKeys.text2sql]: {
    default: false,
  },
  [FeatureFlagKeys.mtuUsage]: {
    default: false,
  },
  [FeatureFlagKeys.showWarehouseLatency]: {
    default: false,
  },
  [FeatureFlagKeys.enableCohortAudience]: {
    default: LOCAL_FEATURE_FLAGS.ENABLE_COHORT_AUDIENCE === 'true',
  },
  [FeatureFlagKeys.enableDataCatalogCustomTypes]: {
    default: false,
  },
  [FeatureFlagKeys.enabledDataCatalogTPWorkspaceCopy]: {
    default: false,
  },
  [FeatureFlagKeys.reportingDelay]: {
    payload: {
      delayInMinutes: 0,
    },
    default: false,
  },
  [FeatureFlagKeys.codegenUI]: {
    default: false,
  },
  [FeatureFlagKeys.barGraphForWarehouseDest]: {
    default: false,
  },
  [FeatureFlagKeys.enableDataCatalogComplexCustomTypes]: {
    default: false,
  },
  [FeatureFlagKeys.enableDataCatalogCustomTypesArrayItems]: {
    default: false,
  },
  [FeatureFlagKeys.enableRetlCursorColumn]: {
    default: false,
  },
  [FeatureFlagKeys.restrictLiveEvents]: {
    default: false,
  },
  [FeatureFlagKeys.enableDestinationEventDetails]: {
    default: false,
  },
  [FeatureFlagKeys.autosaveTransformationsTestEvents]: {
    default: false,
  },
  [FeatureFlagKeys.enableTPNewOverview]: {
    default: false,
  },
  [FeatureFlagKeys.enableTrackingPlansEventNewAPIResponse]: {
    default: false,
  },
  [FeatureFlagKeys.trackingPlanValidateStageInUTLiveEvents]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.enableNewPermissionsModel]: {
    default: false,
  },
} as const;

const flagPrefix = 'AMP_';

class FeatureFlagService {
  hasInitialized = false;

  initializationPromise: Promise<void> | undefined;

  flagsmith: IFlagsmith | undefined;

  private isReady(): this is { flagsmith: IFlagsmith } {
    return this.hasInitialized;
  }

  private async initializeFlagsmith() {
    try {
      const flagsmithModule = await import('flagsmith');
      this.flagsmith = flagsmithModule.default;

      await this.flagsmith.init({
        environmentID: flagsmithConfig.environmentKey,
        cacheFlags: true,
        preventFetch: true,
        realtime: false,
        enableAnalytics: true,
      });

      if (this.flagsmith.loadingState!.error) {
        throw new Error('Failed to load Flagsmith');
      }

      this.hasInitialized = true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('[flagsmith] failed to initialize:', error);
    }
  }

  constructor() {
    if (flagsmithConfig.environmentKey) {
      this.initializationPromise = this.initializeFlagsmith();
    }
  }

  // Call flagsmith identify when and if the library has been loaded and
  // initialized successfully
  public async identify(user: User) {
    const { workspaceId, organizationId } = user;

    return this.initializationPromise
      ?.then(async () => {
        // At this point, the flagsmith library has been loaded and initialized
        if (!this.isReady()) {
          // eslint-disable-next-line no-console
          console.warn('Flagsmith failed to load, skipping start');
          return;
        }

        await this.flagsmith.identify(workspaceId, { organizationId });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.warn('[flagsmith] identify call failed:', error);
      });
  }

  // NOTE:
  // All the following methods are being called after `identify` has been
  // awaited. If the identify call failed, the methods will return the default
  // flag values.

  // Reset the flags if the identify call was successful
  public reset() {
    if (!this.isReady()) {
      // eslint-disable-next-line no-console
      console.warn('Flagsmith failed to load, skipping reset');
      return;
    }

    this.flagsmith.logout();
  }

  public isFeatureEnabled(flag: FeatureFlagKeys): boolean {
    if (!this.isReady()) {
      return featureFlagsDefaults[flag].default;
    }

    return this.flagsmith.hasFeature(flag, { fallback: featureFlagsDefaults[flag].default });
  }

  public isDestinationFeatureEnabled(
    destinationType: string,
    flag: DestinationFeatureFlags,
  ): boolean {
    const flagDefault = destinationFeatureFlagsDefaults[flag] ?? { payload: {}, default: false };

    if (!this.isReady()) {
      return (flagDefault.payload as Record<string, boolean>)?.[destinationType] === true;
    }

    const value = this.flagsmith.getValue(flag, {
      json: true,
      fallback: flagDefault.payload,
    }) as Record<string, boolean>;

    return value?.[destinationType] === true;
  }

  public getAllFeatureFlags(): Record<string | FeatureFlagKeys, boolean> {
    if (!this.isReady()) {
      return Object.fromEntries(
        Object.entries(featureFlagsDefaults)
          .filter(([key]) => !destinationFeatureFlagKeys.includes(key as DestinationFeatureFlags))
          .map(([key, value]) => [`${flagPrefix}${key}`, value.default]),
      );
    }

    return Object.fromEntries(
      Object.entries(this.flagsmith.getAllFlags())
        .filter(([key]) => !destinationFeatureFlagKeys.includes(key as DestinationFeatureFlags))
        .map(([key, flag]) => [`${flagPrefix}${key}`, flag.enabled]),
    );
  }

  public getAllDestinationFeatureFlags(destinationType: string): Record<string, boolean> {
    if (!this.isReady()) {
      return Object.fromEntries(
        Object.entries(destinationFeatureFlagsDefaults)
          .filter(([key]) => !featureFlagKeys.includes(key as FeatureFlagKeys))
          .map(([key, value]) => [`${flagPrefix}${key}`, value.default]),
      );
    }

    return Object.fromEntries(
      Object.entries(this.flagsmith.getAllFlags())
        .filter(([key]) => !featureFlagKeys.includes(key as FeatureFlagKeys))
        .map(([flagId]) => [
          `${flagPrefix}${flagId}`,
          this.isDestinationFeatureEnabled(destinationType, flagId as DestinationFeatureFlags),
        ]),
    );
  }

  public getFeatureValue<T extends FeatureFlagsWithPayload>(
    flag: FeatureFlagsWithPayload,
  ): FeatureFlagPayloads[T] {
    const flagDefaults = featureFlagsDefaults[flag];
    const fallback = flagDefaults.payload;

    if (!this.isReady()) {
      return fallback;
    }

    return this.flagsmith.getValue(flag, { json: true, fallback });
  }
}

export const experiment = new FeatureFlagService();
