import { Flex } from '@ui-library/flex';
import styled from 'styled-components';
import { Subtext } from '@ui-library/typography';
import { Button } from '@ui-library/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from '@css/theme';

export const StyledFlex = styled(Flex)`
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: ${theme.token.borderRadius}px;
  width: 60vw;
  height: 70vh;
  padding: ${theme.token.paddingXL}px;
`;
export const StyledSubtext = styled(Subtext)`
  color: ${theme.token.colorTextDescription};
`;
export const StyledErrorBody = styled(Subtext)`
  margin: ${theme.token.marginLG}px 0;
  flex-grow: 1;
  color: ${theme.token.colorTextDescription};
  overflow-y: auto;
`;
export const ErrorBackButton = styled(Button)`
  margin-left: ${theme.token.marginXS}px;
`;

export const CustomModalContainer = styled.div.attrs({
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation(),
})`
  max-height: 100vh;
  border-radius: ${theme.token.borderRadiusXL}px;
  padding: ${theme.token.paddingXL}px;
  background: ${theme.token.colorBgLayout};
  box-shadow: ${theme.token.boxShadowSecondary};
  overflow-y: auto;
`;
export const CrossIcon = styled(FontAwesomeIcon)`
  margin-bottom: ${theme.token.marginXS}px;
  cursor: pointer;
`;
export const CustomContent = styled.div<{ hideMargin?: boolean }>`
  ${({ hideMargin }) => (!hideMargin ? `margin: ${theme.token.marginLG}px 0;` : '')}
`;

export const CustomButton = styled(Button)`
  min-width: 88px;
`;
