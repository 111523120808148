import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Skeleton } from 'antd';

import useStores from '@stores/useStores';
import { RedirectRoute } from '@components/routes/redirect';

import { AccessManagementCtx, AccessManagementStore } from '../store';
import { ACCESS_MANAGEMENT_PATH } from './paths';
import { PageContainer } from '../components/styles';

const AccessManagement = lazy(() =>
  import(/* webpackChunkName: "accessManagement" */ '@components/accessManagement').then(
    (module) => ({
      default: module.AccessManagement,
    }),
  ),
);

const DefaultWorkspacePolicyPage = lazy(() =>
  import(
    /* webpackChunkName: "accessManagement.policy" */ '../components/policy/defaultWorkspacePolicyPage'
  ).then((module) => ({
    default: module.DefaultWorkspacePolicyPage,
  })),
);

const GroupDetails = lazy(() =>
  import(/* webpackChunkName: "accessManagement.groupDetails" */ '../components/groupDetails').then(
    (module) => ({
      default: module.GroupDetails,
    }),
  ),
);

export const AccessManagementRoutes = () => {
  const rootStore = useStores();
  const store = React.useMemo(() => new AccessManagementStore(rootStore), []);

  return (
    <Suspense fallback={<PageContainer as={Skeleton} active />}>
      <AccessManagementCtx.Provider value={store}>
        <Switch>
          <Route
            exact
            path={ACCESS_MANAGEMENT_PATH.ACCESS_MANAGEMENT}
            component={AccessManagement}
          />

          <Route
            exact
            path={ACCESS_MANAGEMENT_PATH.DEFAULT_WORKSPACE_POLICY}
            component={DefaultWorkspacePolicyPage}
          />

          <Route exact path={ACCESS_MANAGEMENT_PATH.GROUP_DETAILS} component={GroupDetails} />

          <RedirectRoute />
        </Switch>
      </AccessManagementCtx.Provider>
    </Suspense>
  );
};
