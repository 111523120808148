import { action, makeObservable, observable } from 'mobx';
import { IRootStore } from '@stores/index';
import { IUsersStore } from './types';
import { ApiUser } from '../api/types';

export class UsersStore implements IUsersStore {
  rootStore: IRootStore;

  @observable users: ApiUser[] = [];

  @observable usersLoading = false;

  constructor(root: IRootStore) {
    makeObservable(this);
    this.rootStore = root;
  }

  @action.bound
  setUsers(users: ApiUser[]) {
    this.users = users;
  }

  @action.bound
  setUsersLoading(usersLoading: boolean) {
    this.usersLoading = usersLoading;
  }

  @action.bound
  getWorkspaceUsers(workspaceId: string) {
    return this.users.filter((user) => user.workspaceIds.includes(workspaceId));
  }

  @action.bound
  getGroupUsers(groupId: string) {
    return this.users.filter((user) => user.groupIds.includes(groupId));
  }
}
