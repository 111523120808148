import { css } from 'styled-components';

export const utilities = css`
  .no-p {
    padding: 0;
  }

  .no-m {
    margin: 0;
  }

  .p-t-xs {
    padding-top: 5px;
  }

  .p-b-xs {
    padding-bottom: 5px;
  }

  .p-h-sm {
    padding-right: 10px;
    padding-left: 10px;
  }

  .p-h-lg {
    padding-right: 30px;
    padding-left: 30px;
  }

  .p-t-sm {
    padding-top: 10px;
  }

  .p-b-sm {
    padding-bottom: 10px;
  }

  .p-r-sm {
    padding-right: 10px;
  }

  .p-l-sm {
    padding-left: 10px;
  }

  .p-b-md {
    padding-bottom: 20px;
  }

  .p-t-md {
    padding-top: 20px;
  }

  .p-r-md {
    padding-right: 20px;
  }

  .p-l-md {
    padding-left: 20px;
  }
  .p-v-sm {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .p-v-md {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .p-t-lg {
    padding-top: 30px;
  }

  .p-b-lg {
    padding-bottom: 30px;
  }

  .p-l-lg {
    padding-left: 30px;
  }

  .p-r-lg {
    padding-right: 30px;
  }

  .p-r-xl {
    padding-right: 50px;
  }

  .m-auto {
    margin: auto;
  }

  .m-l-auto {
    margin-left: auto;
  }

  .m-r-auto {
    margin-right: auto;
  }

  .m-t-no {
    margin-top: 0;
  }

  .m-t-xs {
    margin-top: 5px;
  }

  .m-r-xs {
    margin-right: 5px;
  }

  .m-b-xs {
    margin-bottom: 5px;
  }

  .m-l-xs {
    margin-left: 5px;
  }

  .m-l-sm {
    margin-left: 10px;
  }

  .m-l-lg {
    margin-left: 24px;
  }

  .m-r-sm {
    margin-right: 10px;
  }

  .m-v-sm {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .m-b-sm {
    margin-bottom: 10px;
  }

  .m-t-sm {
    margin-top: 10px;
  }

  .m-l-md {
    margin-left: 20px;
  }

  .m-r-md {
    margin-right: 20px;
  }

  .m-b-md {
    margin-bottom: 20px;
  }

  .m-t-md {
    margin-top: 20px;
  }

  .m-h-sm {
    margin-left: 10px;
    margin-right: 10px;
  }

  .m-h-md {
    margin-left: 20px;
    margin-right: 20px;
  }

  .m-v-md {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .m-v-lg {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .m-t-lg {
    margin-top: 30px;
  }

  .m-b-lg {
    margin-bottom: 30px;
  }

  .m-r-lg {
    margin-right: 30px;
  }

  .m-v-sd {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .m-t-sd {
    margin-top: 16px;
  }

  .m-b-sd {
    margin-bottom: 16px;
  }

  .m-l-xl {
    margin-left: 65px;
  }

  .m-t-xd {
    margin-top: 40px;
  }

  .m-r-xd {
    margin-right: 40px;
  }

  .m-r-xxl {
    margin-right: 120px;
  }

  .m-t-svh {
    margin-top: 15vh;
  }

  .m-md {
    margin: 20px;
  }

  .text-center {
    text-align: center;
  }

  .underline {
    text-decoration: underline;
  }

  .b-t-grey {
    border-top: 1px solid #e0e0e0;
  }

  .d-block {
    display: block;
  }

  .align-self-center {
    align-self: center;
  }

  .align-self-end {
    align-self: flex-end;
  }

  .align-self-start {
    align-self: flex-start;
  }

  .d-flex {
    display: flex;
  }

  .flex-1 {
    flex: 1;
  }

  .flex-item-right {
    margin-left: auto;
  }

  .d-inline {
    display: inline;
  }

  .v-hidden {
    visibility: hidden;
  }

  .flex-shrink-0 {
    flex-shrink: 0;
  }

  .cursor-not-allowed {
    cursor: not-allowed !important;
  }

  .position-abs {
    position: absolute;
  }

  .position-rel {
    position: relative;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .word-wrap {
    word-wrap: break-word;
  }

  .space-nowrap {
    white-space: nowrap;
  }

  .text-nowrap {
    text-wrap: nowrap;
  }

  .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .link-blue {
    color: ${({ theme }) => theme.token.colorLink};
  }

  .fit-height {
    height: fit-content;
  }

  .fit-width {
    width: fit-content;
  }

  .full-height {
    height: 100%;
  }

  .full-width-vw {
    width: 100vw;
  }

  .full-width {
    width: 100%;
  }

  .full-cent {
    height: 100%;
    width: 100%;
  }

  .overflow-clip {
    overflow: clip;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .overflow-auto {
    overflow: auto;
  }
`;
