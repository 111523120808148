import styled from 'styled-components';
import { Skeleton } from 'antd';
import { Flex } from '@ui-library/flex';

export const PageContainer = styled(Flex)`
  padding: 2.5rem 3rem;
`;

export const BadgeSkeleton = styled(Skeleton.Input).attrs({
  size: 'small',
  active: true,
})`
  height: 20px !important;
  border-radius: 10px !important;
`;

export const CurrentWorkspaceIndicator = styled(Flex)`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  background-color: ${({ theme }) => theme.token['green.3']};
  border: 1px solid ${({ theme }) => theme.token['green.5']};
  border-radius: 50%;
`;
