import React from 'react';
import { Switch as AntSwitch, SwitchProps as AntSwitchProps } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

/*
 * The disabled and loading states set `pointer-events: none`. Wrap it in a
 * `div` when you want a Tooltip or a similar component to appear in the
 * afforementioned states.
 */
export const Switch = (props: AntSwitchProps) => (
  <AntSwitch
    checkedChildren={<FontAwesomeIcon icon={solid('check')} size="lg" />}
    unCheckedChildren={<FontAwesomeIcon icon={solid('xmark')} size="lg" />}
    {...props}
  />
);
