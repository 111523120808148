import React, { forwardRef } from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, InputRef } from 'antd';
import { InputProps } from 'antd/lib/input/Input';
import { LargeText } from '@ui-library/typography';

type IProps = {
  label?: string;
};

type SearchComponentProps = InputProps & IProps & Required<Pick<InputProps, 'onChange'>>;

// To search on the client side
export const Search = forwardRef<InputRef, SearchComponentProps>(
  ({ onChange, label, size = 'large', ...rest }, ref) => (
    <>
      {label && <LargeText className="m-b-xs">{label}</LargeText>}
      <Input
        allowClear={{
          clearIcon: (
            <FontAwesomeIcon fontSize={16} className="cursor-pointer" icon={regular('xmark')} />
          ),
        }}
        ref={ref}
        prefix={<FontAwesomeIcon className="cursor-pointer" icon={regular('search')} />}
        onChange={onChange}
        {...rest}
        size={size}
      />
    </>
  ),
);

// To search on the server side
export const SearchV2 = Input.Search;
