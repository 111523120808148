import React from 'react';
import { computed, makeObservable } from 'mobx';

import { IRootStore } from '@stores/index';

import { PoliciesStore } from './policiesStore';
import { GroupsStore } from './groupsStore';
import { UsersStore } from './usersStore';
import { IAccessManagementStore, IGroupsStore, IPoliciesStore, IUsersStore } from './types';

export class AccessManagementStore implements IAccessManagementStore {
  sessionId: number;

  rootStore: IRootStore;

  policiesStore: IPoliciesStore;

  groupsStore: IGroupsStore;

  usersStore: IUsersStore;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.policiesStore = new PoliciesStore(rootStore, this);
    this.groupsStore = new GroupsStore(rootStore);
    this.usersStore = new UsersStore(rootStore);
    this.sessionId = Date.now();
  }

  @computed
  get workspaces() {
    return this.rootStore.organizationStore.workspaces;
  }
}

export const AccessManagementCtx = React.createContext<IAccessManagementStore | undefined>(
  undefined,
);

export const useAccessManagementStore = () =>
  React.useContext(AccessManagementCtx) as IAccessManagementStore;
